// Function to split paths for breadcump

const pathspliter = (data) => {

  var loc = [];
var breadpath = data.split(/[/]/)
breadpath.shift()

breadpath.forEach((item,i) => {
var value = null;
var path = null;
// console.log(item,i,loc[0])

if(i === 0) {
  if(item === 'en' || item === 'el') {
    value = 'HOME';
    path=`/${item}`
  }
  else {
    value=item;
    path=`/${item}`
  }
  loc.push({value: value, path: path})
}
else {
  value=item;
  path=`${loc[i-1].path}/${item}`
  loc.push({value: value, path: path})
}





})

return loc


}




export default pathspliter;
