
// NOTE: Loadable loads the calendar only in the browser because gatsby build fails without it
// NOTE: because on the render it tries to access the window that doesnt exist in gatsby build  process

import React from "react"
import Loadable from 'react-loadable';
import Breadcrumb from '../components/breadcrumb'
import Header from '../components/header'
import Seo from '../components/seo'
import pathspliter from '../functions/pathspliter'
import {graphql} from 'gatsby'
import {Container} from 'react-bootstrap'
import Footer from '../components/footer'



const LoadableCallendar=Loadable({
    loader:()=>import('../components/callendar'),
    loading() {
        return <div>Loading...</div>
      }
})


const CallendarPage = (props) => (
  <div>
    <Seo canonical={`${props.pageContext.baseurl}${props.path}`} metainfo={props.data.drupal.metainfo.entities[0].entityMetatags} />
    <Header contactinfo={props.pageContext.contactinfo.entities[0]} langcode={props.pageContext.langcode} langcontainer={props.pageContext.langcontainer} path={props.path} menu={props.pageContext.menu}/>


     <Breadcrumb title={props.pageContext.title} path={pathspliter(props.path)}/>

     <Container className="container-big">
     <section className="py-6">
         <LoadableCallendar langcode={props.pageContext.langcode} programs={props.data.drupal.program.entities} events={props.data.drupal.events.entities}/>
      </section>
     </Container>

     <Footer social={props.pageContext.social} langcode={props.pageContext.langcode}/>
  </div>
)

export default CallendarPage

export const query = graphql`
  query($langcode: DRUPAL_LanguageId) {
    drupal {
  events:nodeQuery(filter: {conditions: [{field: "type", value: "class", language: $langcode}]}) {
    entities(language: $langcode) {
      ... on DRUPAL_NodeClass {
        title
        fieldClassStart
        fieldClassEnd
        fieldDays

        fieldProgram {
           entity {
             ... on DRUPAL_NodeProgram {
               title
               body {
                 summary
               }
               fieldProgramImage {
                 entity {
                   thumbnail {
                     url
                     alt
                     imageFile {
                       childImageSharp {
                         fixed(quality: 80, height: 150, width: 200, fit: COVER) {
                           src
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
      }
    }
  }

  program:nodeQuery(filter: {conditions: [{field: "type", value: "program", language: $langcode}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodeProgram {
      title


    }
  }
}

metainfo:nodeQuery(filter: {conditions: [{field: "type", value: "schedule"}]}) {
entities(language:$langcode) {
 ... on DRUPAL_NodeSchedule {

   entityMetatags {
     value
     key
   }

 }
}
}

}
  }
`
