import React, { Component } from 'react';
import {Container} from 'react-bootstrap'
// import {Link} from 'gatsby'

class Footer extends Component {

  render() {
    return (
      <footer>

          <img className="footlogo d-block mx-auto" style={{maxWidth:'200px'}} src="/logo.jpg" alt=""/>


        <div className="footcontainer py-6 text-center">
        <Container>
          <h4>{this.props.langcode === 'EN' ? "Follow Us":"ΑΚΟΛΟΥΘΗΣΤΕ ΜΑΣ"}</h4>
          <div className="social">
          {this.props.social.fieldSocialFacebook?   <a rel="noopener noreferrer" target="_blank" href={this.props.social.fieldSocialFacebook}>
             <span className="fab fa-facebook-square"></span>
            </a>: null }

            {this.props.social.fieldSocialLinkedin?   <a rel="noopener noreferrer" target="_blank" href={this.props.social.fieldSocialLinkedin}>
               <span className="fab fa-linkedin-in"></span>
              </a>: null }

              {this.props.social.fieldSocialTwitter?   <a rel="noopener noreferrer" target="_blank" href={this.props.social.fieldSocialTwitter}>
                 <span className="fab fa-twitter"></span>
                </a>: null }


                {this.props.social.fieldSocialInstagram?   <a rel="noopener noreferrer" target="_blank" href={this.props.social.fieldSocialInstagram}>
                   <span className="fab fa-instagram"></span>
                  </a>: null }



        






          </div>
          {/* <div className="footmenu d-flex flex-md-row flex-column justify-content-around flex-wrap mt-3">
            <Link to="/">ΑΡΧΙΚΗ</Link>
            <Link to="/">Ο ΧΩΡΟΣ</Link>
            <Link to="/">ΕΚΠΑΙΔΕΥΤΕΣ</Link>
            <Link to="/">ΕΒΕΝΤΣ</Link>
            <Link to="/">ΠΡΟΓΡΑΜΜΑ</Link>
            <Link to="/">ΕΠΙΚΟΙΝΩΝΙΑ</Link>





          </div> */}
          <div className="copyright">
            <div className="copyright-text"><p>Copyright © {new Date().getFullYear()}. All Rights Reserved. Website by <a target="_blank" rel="noopener noreferrer" href="https://webdimension.gr">WebDimension</a></p>
               </div>
          </div>


        </Container>

        </div>

      </footer>
    );
  }

}

export default Footer;
