import React, { Component } from 'react';
import {Container} from 'react-bootstrap'
import {Link} from 'gatsby'

class Breadcrumb extends Component {

  render() {
    return (
      <div className="breadcrumbs-wrap title-center show-title-1">
      <Container>

      <h1 className="ys-page-title page-title ">{this.props.title || "Class Descriptions"}</h1>
      <ul className="ys-breadcrumbs breadcrumbs">
      {this.props.path.map((item,i) => {
return (

<li key={i} className={(this.props.path.length === Number(i+1)) ? "trail-end" : ""}>
{(this.props.path.length === Number(i+1)) ? <span>{item.value.toUpperCase()}</span> :  <Link to={item.path}>{item.value.toUpperCase()}</Link>}

</li>
)
})}
							{/* <li><a href="https://velikorodnov.com/wordpress/jogasana/" title="Jogasana - Yoga Oriented WordPress Theme">Home</a></li>
               <li className="trail-end">Class Descriptions</li> */}
						</ul>

      </Container>


      </div>
    );
  }

}

export default Breadcrumb;
