import React, { Component } from 'react';
import { navigate } from "gatsby"

class Langselector extends Component {
   constructor(props) {
     super(props)

     this.state = {
       height:0
     }
   }

  handledisplay = () => {

    this.setState({
      height: this.state.height? 0:200
    })
  }

  handlelink = (path) => {

    navigate(path)
  }

  render() {

    const createdefaultlang = () => {
      let def = "";
      this.props.langcontainer.forEach((lang) => {
        if(lang.key.toUpperCase() === this.props.langcode.toUpperCase()) {
          def = lang.key.toUpperCase()
          if(def === 'EL'){
            def="Ελληνικα"
          }
          if(def=== 'EN') {
            def="English"
          }
          return;
        }
      })
      return def
    }
    return (
      <div   className="langselector">
      <div role="definition" onClick={()=> this.handledisplay()} className="selected d-flex align-items-center">
        <i className="fas fa-globe"></i><span className="ml-1">{createdefaultlang()}</span>
      </div>

      <div style={{maxHeight:`${this.state.height}px`}} className="options">
        {this.props.langcontainer.map((item,i) => {
          let langcode = item.key

          if(item.key === 'el') {
            langcode="Ελληνικα"
          }

          if(item.key === 'en') {
            langcode="English"
          }


          return (
            <div key={i} className="d-flex align-items-center">
              <span role="definition" onClick={()=>this.handlelink(item.path)}  className="w-100 p-2 langitem">{langcode}</span>
            </div>
          )
        })}





      </div>

    </div>
    );
  }

}

export default Langselector;
