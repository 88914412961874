

import React from "react"
import {Container, Navbar, Nav, Row, Col, NavDropdown} from 'react-bootstrap'
import {Link} from 'gatsby'
// import LangSelect from './languageselect'
import Langselector from './mylang.js'


var scrollprev=0;
class Header extends React.Component{

handleScroll = (event) => {
  const winScroll =document.body.scrollTop || document.documentElement.scrollTop

   const height =document.documentElement.scrollHeight -
     document.documentElement.clientHeight

   const scrolled = winScroll / height
   if(scrolled - scrollprev > 0) {

     document.querySelector(".scrollnav").style.zIndex="-100";
     document.querySelector(".scrollnav").style.opacity="0";
   }
   else {


     if(scrolled > 0.20) {

       document.querySelector(".scrollnav").style.zIndex="10";
       document.querySelector(".scrollnav").style.opacity="1";
     }
     else {
       document.querySelector(".scrollnav").style.zIndex="-100";
       document.querySelector(".scrollnav").style.opacity="0";
     }
   }
   scrollprev = scrolled;


}

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
}

componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
}

  render() {

    return (
      <div>

      <header>
      {/* <LangSelect langcode={this.props.langcode} langcontainer={this.props.langcontainer}/> */}



      <Container className="container-big"  >

        <Navbar  expand="lg">


                <Navbar.Brand className='navbrandcontainer' ><Link to={`/${this.props.langcode.toLowerCase()}`}><img   src="/yogalogo.png" alt="logo"/></Link></Navbar.Brand>



              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="flex-column" id="basic-navbar-nav">

                <Nav className="ml-auto">
                  <Row>
                     <Col className="d-md-flex d-none  justify-content-md-end mb-4" xs={12}  md={12}>





                     <div className="mr-2"><i className="fas fa-map-marker-alt headicon"></i><span className="p-2">{this.props.contactinfo.fieldAddress}</span></div>
                     <div className="mr-2" ><i className="fas fa-phone-alt headicon"></i><span className="p-2">{this.props.contactinfo.fieldPhone}</span></div>
                    <div className="mr-2"><i className="fas fa-envelope headicon"></i><span className="p-2"><a href={`mailto:${this.props.contactinfo.fieldEmail}`}>{this.props.contactinfo.fieldEmail}</a></span></div>



                    </Col>


                     <Col className="d-md-flex  justify-content-md-end" xs={12}  md={12}>
                     {this.props.menu.links.map((item,i) => {
                       if(!item.links[0]) { //check an exei submenu TODO active stin elliniki home
                         return (
                           <Link key={i} className={`nav-link ${this.props.path === item.url.path? "active":""}`}  to={item.url.path} ><span className="p-2">{item.label}</span></Link>
                         )
                       }
                       else {
                         return (

                            <NavDropdown className="bootstrapdrop pl-2 p-md-0" title={item.label} id="basic-nav-dropdown">


                                {item.links.map((submenu,i) => {
                                  return (
                                    <NavDropdown.Item><Link key={i} className={`nav-link ${this.props.path === submenu.url.path? "active":""}`}  to={submenu.url.path} ><em className="p-2">{submenu.label}</em>
                                   </Link></NavDropdown.Item>
                                  )
                                })}


                           </NavDropdown>
                         )
                       }

                     })}



                       </Col>
                  </Row>




                  {/* {this.props.menu.map((item,i)=> {
                    return (
                       <Link className={`nav-link ${this.props.path === item.url.path ? 'active' : null}`}  to={item.url.path} key={i}>{item.label}</Link>
                    )

                  })} */}










                  <Langselector langcode={this.props.langcode} langcontainer={this.props.langcontainer}/>
                </Nav>

              </Navbar.Collapse>





  </Navbar>




      </Container>



      </header>


      <header className="scrollnav">




      <Container className="container-big"  >

        <Navbar  expand="lg">


                <Navbar.Brand className='navbrandcontainer' ><Link to={`/${this.props.langcode.toLowerCase()}`}><img   src="/yogalogo.png" alt="logo"/></Link></Navbar.Brand>



              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="flex-column" id="basic-navbar-nav">

                <Nav className="ml-auto">
                  <Row>



                     <Col className="d-md-flex  justify-content-md-end" xs={12}  md={12}>
                     {this.props.menu.links.map((item,i) => {
                       if(!item.links[0]) { //check an exei submenu TODO active stin elliniki home
                         return (
                           <Link key={i} className={`nav-link ${this.props.path === item.url.path? "active":""}`}  to={item.url.path} ><span className="p-2">{item.label}</span></Link>
                         )
                       }
                       else {
                         return (
                           <NavDropdown className="bootstrapdrop pl-2 p-md-0" title={item.label} id="basic-nav-dropdown">


                               {item.links.map((submenu,i) => {
                                 return (
                                   <NavDropdown.Item><Link key={i} className={`nav-link ${this.props.path === submenu.url.path? "active":""}`}  to={submenu.url.path} ><em className="p-2">{submenu.label}</em>
                                  </Link></NavDropdown.Item>
                                 )
                               })}


                          </NavDropdown>
                         )
                       }

                     })}



                       </Col>
                  </Row>




                  {/* {this.props.menu.map((item,i)=> {
                    return (
                       <Link className={`nav-link ${this.props.path === item.url.path ? 'active' : null}`}  to={item.url.path} key={i}>{item.label}</Link>
                    )

                  })} */}










             <Langselector langcode={this.props.langcode} langcontainer={this.props.langcontainer}/>
                </Nav>

              </Navbar.Collapse>





  </Navbar>




      </Container>



      </header>
</div>


    )
  }

}


export default Header
