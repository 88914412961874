import React, { Component } from 'react';
import {Helmet} from 'react-helmet'

class Seo extends Component {

  render() {
    return (
      <Helmet>
      {this.props.metainfo.map((item,i) => {
if(item.key === 'title') {
return (
  <title key={i}>{item.value}</title>
)
}
else if(item.key === 'canonical') {
return (
  <link key={i} rel="canonical" href={this.props.canonical} />
)
}

else if(item.key === 'description') {
return (
  <meta key={i} name="description" content={item.value}/>
)
}

else {
return null;
}
})}
      </Helmet>
    );
  }

}

export default Seo;
